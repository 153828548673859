<template>
  <div class="card lesson-section">
    <div
      class="h-100 d-flex align-items-center svg-icon svg-icon-sm text-overflow"
    >
      <inline-svg
        class="svg-icon mr-2 mw-30 sortHandle"
        src="/media/svg/icons/Neolex/Basic/menu-dots.svg"
      />
    </div>
    <div class="lesson-section__name">
      {{ section.name }}
    </div>
    <div class="lesson-section__actions">
      <b-button
        class="lesson-section__actions__button button-info mr-2"
        size="sm"
        @click="onClickViewButton"
      >
        <span class="svg-icon">
          <inline-svg
            class="lesson-section__actions__button__icon"
            src="/media/svg/icons/Neolex/Basic/eye.svg"
          />
        </span>
      </b-button>
      <b-button
        class="lesson-section__actions__button mr-2"
        size="sm"
        variant="info"
        @click="onClickEditButton"
      >
        <span class="svg-icon">
          <inline-svg
            class="lesson-section__actions__button__icon"
            src="/media/svg/icons/Neolex/Basic/edit-2.svg"
          />
        </span>
      </b-button>
      <template v-if="!section.isNew">
        <b-button
          v-if="showBtnActive"
          class="lesson-section__actions__button mr-2"
          size="sm"
          variant="info"
          @click="showPopupChangeStatus(1)"
        >
          <span class="svg-icon">
            <inline-svg
              class="lesson-section__actions__button__icon"
              src="/media/svg/icons/Neolex/Basic/power.svg"
            />
          </span>
        </b-button>
        <b-button
          v-else
          class="lesson-section__actions__button mr-2"
          size="sm"
          variant="danger"
          @click="showPopupChangeStatus(0)"
        >
          <span class="svg-icon">
            <inline-svg
              class="lesson-section__actions__button__icon"
              src="/media/svg/icons/Neolex/Basic/power.svg"
            />
          </span>
        </b-button>
      </template>
      <b-button
        v-if="showBtnActive"
        class="lesson-section__actions__button"
        size="sm"
        variant="danger"
        @click="showPopupDeleteSection"
      >
        <span class="svg-icon">
          <inline-svg
            class="lesson-section__actions__button__icon"
            src="/media/svg/icons/Neolex/Basic/trash-2.svg"
          />
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonSection',
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      url: {
        changeState: '/LessonSection/{id}/State',
      },
    };
  },
  computed: {
    showBtnActive() {
      return this.section?.status !== 1;
    },
  },
  methods: {
    onClickViewButton() {
      this.$emit('view', this.section);
    },
    onClickEditButton() {
      this.$emit('edit', this.section);
    },
    onClickDeleteButton() {
      this.$emit('delete');
    },
    showPopupDeleteSection() {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa ${this.section.name} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.onClickDeleteButton();
          }
        }.bind(this),
      );
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.onClickToggleStatusButton(state);
          }
        }.bind(this),
      );
    },
    onClickToggleStatusButton(state) {
      if (!this.section.id) return;
      this.$emit('change-status', {
        state,
        id: this.section.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-section {
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  column-gap: 16px;
  height: 55px;
  padding: 10px;
  background-color: #fafafa;
  margin-bottom: 16px;
  cursor: move !important;
  cursor: -webkit-grabbing !important;

  .lesson-section__name {
    color: #000000;
    font-weight: 600;
    // max-width: calc(100% - 200px);
  }

  .lesson-section__actions {
    .lesson-section__actions__button {
      height: 32px;
      width: 32px;

      .lesson-section__actions__button__icon {
        height: 12px;
        width: 12px;
      }
    }
  }

  .button-info,
  .button-info:hover,
  .button-info:active {
    border-color: #def2f1 !important;
    color: #008479 !important;
    background-color: #def2f1 !important;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
